import React, { Component } from 'react';
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import Card from './Card';
import Row from './Row';
import Container from './Container';
import Contact from './Contact';
import AskrImage from '../images/askr.png';
import InkcaseImage from '../images/inkcase.png';
import Column from './Column';
import Sidebar from './Sidebar';

class Index extends Component {
    render() {
        return (<div>
            <Navigation />
            <Header title="Laserbot Labs" backgroundColor='#2E527D' subtitle="Mobile app development" />
            <Container>
                <Row>
                    <Column>

                        <Content title="What We Do" content={<div>
                            <p>Laserbot Labs is a company creating mobile apps.</p>
                            <a href='https://play.google.com/store/apps/developer?id=Laserbot+Labs&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                                target="_blank" rel="noopener noreferrer">
                                <img width="240px" height="auto" alt='Get it on Google Play'
                                    src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                            </a>
                        </div>} />
                        <Row>
                            <Card title={<h4 class="card-title">Askr for CuriousCat</h4>} text={<p class="card-text">
                                <p>Askr for CuriousCat is an app for browsing the social network CuriousCat on your
              Android device.</p>
                                <p> It features feed, profile, discover, inbox, notifications and much more. It is the best
              way to navigate CuriousCat on smartphones.</p>
                            </p>} image={AskrImage} link="askr" />
                            <Card title={<h4 class="card-title">Inkcase</h4>} text={<p class="card-text">
                                <p>Inkcase is a network of user generated color palettes. It features a feed, search, profile,
                                and much more. It is the best way to find and create beautiful color palettes on
              smart phones.</p>
                            </p>} image={InkcaseImage} link="inkcase" />
                        </Row>
                    </Column>
                    <Sidebar>
                        <Contact />
                    </Sidebar>
                </Row>

            </Container>
            <Footer />
        </div>);
    }
}

export default Index;