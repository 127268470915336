import React from 'react';

function Content(props) {
    return (
        <div class="col-md-8 mb-5">
            <h2>{props.title}</h2>
            <hr />
            {props.content}
        </div>
    );
}

export default Content;