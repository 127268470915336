import React from 'react';

function Contact() {
    return (<div>
        <h2>Contact Us</h2>
        <hr />
        <address>
            <strong>Email</strong>
            <br />
            <a href="mailto:hello@laserbotlabs.com">hello@laserbotlabs.com</a>
        </address>
        <address>
            <strong>Facebook</strong>
            <br />
            <a href="https://facebook.com/laserbotlabs">facebook.com/laserbotlabs</a>
        </address>
        <address>
            <strong>Instagram</strong>
            <br />
            <a href="https://instagram.com/laserbotlabs">instagram.com/laserbotlabs</a>
        </address>
        <address>
            <strong>Twitter</strong>
            <br />
            <a href="https://twitter.com/laserbotlabs">twitter.com/laserbotlabs</a>
        </address>
        <address>
            <strong>Tumblr</strong>
            <br />
            <a href="https://laserbotlabs.tumblr.com">laserbotlabs.tumblr.com</a>
        </address>
    </div>)
}

export default Contact;