import React from 'react';

function Links(props) {
    return (<div>
        <h2>Links</h2>
        <hr />
        {props.links}
    </div>)
}

export default Links;