import React from 'react';

function Header(props) {
    return (
        <header class="py-5 mb-5" style={{ backgroundColor: props.backgroundColor }}>
            <div class="container h-100">
                <div class="row h-100 align-items-center">
                    <div class="col-lg-12">
                        <h1 class="display-4 text-white mt-5 mb-2">{props.title}</h1>
                        <p class="lead mb-5 text-white-50">{props.subtitle}</p>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;