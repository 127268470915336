import React, { Component } from 'react';
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import Row from './Row';
import Container from './Container';
import Contact from './Contact';
import Column from './Column';
import Links from './Links';
import Sidebar from './Sidebar';

class Inkcase extends Component {
    render() {
        return (<div>
            <Navigation />
            <Header title="Inkcase" backgroundColor='#009688' subtitle="Color palette app" />
            <Container>
                <Row>
                    <Column>
                        <Content title="Inkcase" content={<div>
                            <p>Inkcase is a network of user generated color palettes. It features a feed, search, profile,
                            and much more. It is the best way to find and create beautiful color palettes on
                    smart phones.</p>
                            <p>The Inkcase app was developed by the Laserbot Labs team.</p>
                        </div>} />
                    </Column>
                    <Sidebar>
                        <Contact />
                        <Links links={<div>
                            <p><a href="privacy_policy.html">Privacy Policy</a></p>

                            <p><a href="terms_and_conditions.html">Terms and Conditions</a></p>

                            <p><a href="licenses.html">Licenses</a></p>
                        </div>} />
                    </Sidebar>
                </Row>
            </Container>
            <Footer />
        </div>);
    }
}

export default Inkcase;