import React from 'react';
import Index from './Index';
import Askr from './Askr';
import Inkcase from './Inkcase';
import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path='/' component={Index} />
        <Route exact path='/askr' component={Askr} />
        <Route exact path='/inkcase' component={Inkcase} />
      </div>
    </Router>
  );
}

export default App;
