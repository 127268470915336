import React from 'react';

function Footer() {
    return (
        <footer class="py-5 bg-dark">
            <div class="container">
                <p class="m-0 text-center text-white">Copyright &copy; Laserbot Labs 2020</p>
            </div>
        </footer>
    );
}

export default Footer;