import React, { Component } from 'react';
import Navigation from './Navigation';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import Row from './Row';
import Container from './Container';
import Contact from './Contact';
import Column from './Column';
import Links from './Links';
import Sidebar from './Sidebar';

class Askr extends Component {
    render() {
        return (<div>
            <Navigation />
            <Header title="Askr for CuriousCat" backgroundColor='#ffb425' subtitle="CuriousCat browser app" />
            <Container>
                <Row>
                    <Column>
                        <Content title="Askr for CuriousCat" content={<div>
                            <p>Askr for CuriousCat is an app for browsing the social network CuriousCat on your Android device. It
                            features feed, profile, discover, inbox, notifications and much more. It is the best way to navigate
                    CuriousCat on smartphones.</p>

                            <p>
                                Askr for CuriousCat features:
                <ul>
                                    <li>Designed following Material design guidelines</li>
                                    <li>Make questions to your friends</li>
                                    <li>Answer questions your friends made</li>
                                    <li>Follow and unfollow users</li>
                                    <li>Share your answers online</li>
                                    <li>See a feed of everybody you following</li>
                                    <li>Notifications from the network</li>
                                    <li>Discover users and posts</li>
                                </ul>
                            </p>

                            <p>Askr for CuriousCat is an unofficial app. This app is not in any way affiliated to CuriousCat and/or
                    its owners.</p>
                            <a href='https://play.google.com/store/apps/developer?id=Laserbot+Labs&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                                target="_blank" rel="noopener noreferrer">
                                <img width="240px" height="auto" alt='Get it on Google Play'
                                    src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>

                        </div>} />
                    </Column>
                    <Sidebar>
                        <Contact />
                        <Links links={<div>
                            <p><a href="privacy_policy.html">Privacy Policy</a></p>

                            <p><a href="privacy_policy_pro.html">Privacy Policy (paid version)</a></p>

                            <p><a href="terms_and_conditions.html">Terms and Conditions</a></p>

                            <p><a href="account_deletion.html">Account Deletion</a></p>


                            <p><a href="licenses.html">Licenses</a></p>
                        </div>} />
                    </Sidebar>
                </Row>
            </Container>
            <Footer />
        </div>);
    }
}

export default Askr;