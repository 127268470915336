import React from 'react';

function Card(props) {
    return (<div class="col-md-4 mb-5">
        <div class="card h-100">
            <img class="card-img-top" src={props.image} alt="" />
            <div class="card-body">
                {props.title}
                {props.text}
            </div>
            <div class="card-footer">
                <a href={props.link} class="btn btn-primary">Find Out More!</a>
            </div>
        </div>
    </div >);
}

export default Card;